import React from "react"
import styled from "styled-components"
import { GlobalColors } from "../../../assets/styles/GlobalVariables"
import { localize } from "../../Atoms/Language"

const StyledWrapper = styled.section`
  p, li {
    color: ${GlobalColors.gray600};
  }
  
  ul {
    padding-left: 20px;
    margin: 20px 0;
  }
`

const InvestmentProcedure = () => (
  <StyledWrapper className="container mt16 mb16">
    <h4 className="header4">{localize("110")}</h4>
    <p className="body">{localize("111")}</p>
    <ul>
      <li className="body">{localize("112")}</li>
      <li className="body">{localize("113")}</li>
      <li className="body">{localize("114")}</li>
      <li className="body">{localize("115")}</li>
      <li className="body">{localize("116")}</li>
      <li className="body">{localize("117")}</li>
    </ul>
    <p className="body">{localize("118")}</p>
  </StyledWrapper>
)

export default InvestmentProcedure
