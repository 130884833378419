import React from "react"
import ForInvestor from "../components/Organisms/StaticSections/forInvestor"
import BuildCarWash from "../components/Organisms/StaticSections/buildCarWash"
import CTABanner from "../components/Organisms/CTABanner/CTABanner"
import InvestmentSteps from "../components/Organisms/StaticSections/InvestmentSteps"
import InvestmentProcedure from "../components/Organisms/StaticSections/investmentProcedure"
import SEO from "../components/seo"
import { localize } from "../components/Atoms/Language"
import { WithIntl } from "../components/Atoms/withTranslate"

const ForInvestorPage = ({language}) => (
  <>
    <SEO
      lang={language}
      title={localize("119")}
      description={localize("120")}
    />
    <ForInvestor/>
    <BuildCarWash/>
    <InvestmentSteps/>
    <InvestmentProcedure/>
    <CTABanner language={language}/>
  </>
)

export default WithIntl()(ForInvestorPage)
