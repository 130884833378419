import React from "react"
import styled from "styled-components"
import GatsbyImage from "gatsby-image"
import { GlobalColors } from "../../../assets/styles/GlobalVariables"

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: ${({ isRevert }) => isRevert ? "row-reverse" : "row"};
  align-items: flex-start;
  justify-content: space-between;
  padding: 20px 0;
  
  & > div {
    flex: 1;
    ${({ isRevert }) => isRevert ? "padding-left" : "padding-right"}: 95px;
    
    @media screen and (max-width: 900px) {
      padding-right: 0;
      padding-left: 0;
    }
    
    p {
      color: ${GlobalColors.gray600};
    }
  }
  
  .image {
    max-width: 50%;
    padding-right: 0;
    
    @media screen and (max-width: 900px) {
      max-width: 100%;
      text-align: center;
    }
  }
  
  @media screen and (max-width: 900px) {
    flex-direction: column;
    
    & > div {
      flex: none;
      width: 100%;
      max-width: 540px;
      margin: 0 auto 20px;
    }
  }
`

const StyledImage = styled(GatsbyImage)`
  flex: 1;
  border-radius: 4px;
  max-width: 100% !important;
  
  @media screen and (max-width: 900px) {
    margin: 0 auto;
    flex: none;
    max-width: 100%;    
  }
`

const ImageWithText = ({ fixed, fluid, children, isRevert, className, imageAltText }) => (
  <div className="container">
    <StyledWrapper className={className} isRevert={isRevert}>
      <div className="text-content">
        {children}
      </div>
      {fixed ?
        <div className="image">
          <StyledImage fixed={fixed} isRevert={isRevert} alt={imageAltText}/>
        </div>
        :
        <div className="image">
          <StyledImage fluid={fluid} isRevert={isRevert}/>
        </div>
      }
    </StyledWrapper>
  </div>
)

export default ImageWithText
