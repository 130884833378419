import React from "react"
import styled from "styled-components"
import { GlobalColors } from "../../../assets/styles/GlobalVariables"
import mapIcon from "../../../assets/images/icons/map.svg"
import handsIcon from "../../../assets/images/icons/hands.svg"
import documentIcon from "../../../assets/images/icons/document.svg"
import cashIcon from "../../../assets/images/icons/cash.svg"
import buildIcon from "../../../assets/images/icons/build.svg"
import checklistIcon from "../../../assets/images/icons/checklist.svg"
import toolsIcon from "../../../assets/images/icons/tools.svg"
import { localize } from "../../Atoms/Language"

const StyledWrapper = styled.section`
  color: ${GlobalColors.white};
  background-color: ${GlobalColors.black};
  padding: 150px 0;

  h2 {
    text-align: center;
  }

  .container {
    max-width: 740px;

    div {
      padding-left: 100px;
      margin-bottom: 64px;
      background-repeat: no-repeat;
      
      @media screen and (max-width: 900px) {
        background-size: 50px;
        background-position: left 20px top;
      } 

      h3 {
        margin-bottom: 0;
      }
    }
  }
  .map {
    background-image: url(${mapIcon});
  }
  .hands {
    background-image: url(${handsIcon});
  }
  .document {
    background-image: url(${documentIcon});
  }
  .cash {
    background-image: url(${cashIcon});
  }
  .build {
    background-image: url(${buildIcon});
  }
  .checklist {
    background-image: url(${checklistIcon});
  }
  .tools {
    background-image: url(${toolsIcon});
  }
`

const InvestmentSteps = () => (
  <StyledWrapper id="etapy-inwestycji">
    <div className="container">
      <h2 className="header2 mb8">{localize("95")}</h2>
      <div className="map">
        <h3 className="header4">{localize("96")}</h3>
        <p className="body-small">{localize("97")}</p>
      </div>
      <div className="hands">
        <h3 className="header4">{localize("98")}</h3>
        <p className="body-small">{localize("99")}</p>
      </div>
      <div className="document">
        <h3 className="header4">{localize("100")}</h3>
        <p className="body-small">{localize("101")}</p>
      </div>
      <div className="cash">
        <h3 className="header4">{localize("102")}</h3>
        <p className="body-small">{localize("103")}</p>
      </div>
      <div className="build">
        <h3 className="header4">{localize("104")}</h3>
        <p className="body-small">{localize("105")}</p>
      </div>
      <div className="checklist">
        <h3 className="header4">{localize("106")}</h3>
        <p className="body-small">{localize("107")}</p>
      </div>
      <div className="tools">
        <h3 className="header4">{localize("108")}</h3>
        <p className="body-small">{localize("109")}</p>
      </div>
    </div>
  </StyledWrapper>
)

export default InvestmentSteps
