import React from "react"
import styled from "styled-components"
import { GlobalColors } from "../../../assets/styles/GlobalVariables"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { localize } from "../../Atoms/Language"
import LocalizedLink from "../../Atoms/LocalizedLink"

const imageQuery = graphql`
  {
    file(name: { eq: "Header" }) {
      childImageSharp {
        fixed(height: 336) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

const StyledWrapper = styled.div`
  width: 100%;
  max-width: 1160px;
  height: 336px;
  background-color: ${GlobalColors.black};
  position: relative;
  margin: 20px 0 160px;
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 80px;

  @media screen and (max-width: 900px) {
    padding: 20px;
    margin-bottom: 60px;
    height: 256px;
  }
`

const StyledImage = styled(Img)`
  position: absolute !important;
  right: 0;
  bottom: 0;
  z-index: 1;

  @media screen and (max-width: 900px) {
    width: 60% !important;
    height: 100% !important;
  }
`

const StyledContent = styled.div`
  position: relative;
  z-index: 2;
  color: ${GlobalColors.white};

  h3 {
    margin: 0;
  }

  span {
    display: block;
    margin: 16px 0 40px;
  }

  @media screen and (max-width: 900px) {
    h3 {
      font-size: 3.8rem;
    }

    span {
      margin: 6px 0 30px;
    }
  }
`

const CTABanner = ({language}) => {
  const data = useStaticQuery(imageQuery)
  return (
    <div className="container">
      <StyledWrapper>
        <StyledContent>
          <h3 className="header3">{localize("37")}</h3>
          <span className="body-large">{localize("38")}</span>
          <LocalizedLink isButton language={language} to="/kontakt">{localize("39")}</LocalizedLink>
        </StyledContent>
        <StyledImage fixed={data.file.childImageSharp.fixed} />
      </StyledWrapper>
    </div>
  )
}

export default CTABanner
