import React from "react"
import styled from "styled-components"
import ImageWithText from "../../Molecules/ImageWithText/ImageWithText"
import { graphql, useStaticQuery } from "gatsby"
import { GlobalColors } from "../../../assets/styles/GlobalVariables"
import { localize } from "../../Atoms/Language"

const imageQuery = graphql`
  {
    file(name: { eq: "investor" }) {
      childImageSharp {
        fixed(height: 400){
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

const StyledHeader = styled.h2`
  margin-bottom: 10px;
`

const StyledSubHeader = styled.h3`
  color: ${GlobalColors.gray600};
  margin: 0;
`

const BuildCarWash = () => {
  const data = useStaticQuery(imageQuery)

  return (
    <div className="container">
      <ImageWithText className="mt16 mb16" isRevert fixed={data.file.childImageSharp.fixed} imageAltText={localize("22")}>
        <StyledHeader className="header3">{localize("90")}</StyledHeader>
        <StyledSubHeader className="header4">{localize("91")}</StyledSubHeader>
        <p className="body">{localize("92")}</p>
        <p className="body">{localize("93")}</p>
        <p className="body">{localize("94")}</p>
      </ImageWithText>
    </div>
  )
}

export default BuildCarWash
