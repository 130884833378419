import React from "react"
import ImageWithText from "../../Molecules/ImageWithText/ImageWithText"
import { graphql, useStaticQuery } from "gatsby"
import { localize } from "../../Atoms/Language"

const imageQuery = graphql`
  {
    file(name: { eq: "carwash5" }) {
      childImageSharp {
        fixed(height: 400) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

const ForInvestor = () => {
  const data = useStaticQuery(imageQuery)

  return (
    <div className="container">
      <ImageWithText className="mt8" fixed={data.file.childImageSharp.fixed} imageAltText={localize("10")}>
        <h1 className="header2">{localize("87")}</h1>
        <p className="body">{localize("88")}</p>
        <p className="body">{localize("89")}</p>
      </ImageWithText>
    </div>
  )
}

export default ForInvestor
